<template>
  <svg
    id="Laag_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 1200"
    class="font-platform font-light"
    aria-hidden="true"
  >
    <text class="cls-1" transform="translate(305.4 308.78) rotate(-37.14)">
      <tspan x="0" y="0">c</tspan>
    </text>
    <text class="cls-1" transform="translate(399.74 240.38) rotate(-24.18)">
      <tspan x="0" y="0">r</tspan>
    </text>
    <text class="cls-1" transform="translate(459.91 210.34) rotate(-11.81)">
      <tspan x="0" y="0">a</tspan>
    </text>
    <text class="cls-1" transform="translate(570.52 189.47) rotate(.93)">
      <tspan x="0" y="0">f</tspan>
    </text>
    <text class="cls-1" transform="translate(641.94 190.49) rotate(10.86)">
      <tspan x="0" y="0">t</tspan>
    </text>
    <text class="cls-1" transform="translate(713.61 202.62) rotate(23.09)">
      <tspan x="0" y="0">s</tspan>
    </text>
    <text class="cls-1" transform="translate(808.88 246.64) rotate(33.2)">
      <tspan x="0" y="0"></tspan>
    </text>
    <text class="cls-1" transform="translate(843.65 267.92) rotate(41.74)">
      <tspan x="0" y="0">•</tspan>
    </text>
    <text class="cls-1" transform="translate(903.67 323.91) rotate(50.25)">
      <tspan x="0" y="0"></tspan>
    </text>
    <text class="cls-1" transform="translate(931.65 351.72) rotate(61.36)">
      <tspan x="0" y="0">c</tspan>
    </text>
    <text class="cls-1" transform="translate(985.33 455.11) rotate(74.38)">
      <tspan x="0" y="0">r</tspan>
    </text>
    <text class="cls-1" transform="translate(1007.5 518.08) rotate(88.09)">
      <tspan x="0" y="0">e</tspan>
    </text>
    <text class="cls-1" transform="translate(1010.92 650.46) rotate(104.87)">
      <tspan x="0" y="0">a</tspan>
    </text>
    <text class="cls-1" transform="translate(980.17 758.1) rotate(117.71)">
      <tspan x="0" y="0">t</tspan>
    </text>
    <text class="cls-1" transform="translate(944.16 824.39) rotate(126.74)">
      <tspan x="0" y="0">i</tspan>
    </text>
    <text class="cls-1" transform="translate(917.44 867.24) rotate(138.94)">
      <tspan x="0" y="0">v</tspan>
    </text>
    <text class="cls-1" transform="translate(829.58 946.52) rotate(155.86)">
      <tspan x="0" y="0">e</tspan>
    </text>
    <text class="cls-1" transform="translate(702.38 997.54) rotate(168.18)">
      <tspan x="0" y="0"></tspan>
    </text>
    <text class="cls-1" transform="translate(662.78 1007.1) rotate(176.74)">
      <tspan x="0" y="0">•</tspan>
    </text>
    <text class="cls-1" transform="translate(580.72 1009.96) rotate(-174.81)">
      <tspan x="0" y="0"></tspan>
    </text>
    <text class="cls-1" transform="translate(540.82 1008.99) rotate(-164.69)">
      <tspan x="0" y="0">s</tspan>
    </text>
    <text class="cls-1" transform="translate(444.24 985.45) rotate(-148.52)">
      <tspan x="0" y="0">e</tspan>
    </text>
    <text class="cls-1" transform="translate(331.71 925.8) rotate(-126.82)">
      <tspan x="0" y="0">w</tspan>
    </text>
    <text class="cls-1" transform="translate(223.25 764.04) rotate(-109.94)">
      <tspan x="0" y="0">i</tspan>
    </text>
    <text class="cls-1" transform="translate(201.9 715.16) rotate(-97.8)">
      <tspan x="0" y="0">n</tspan>
    </text>
    <text class="cls-1" transform="translate(183.26 600.7) rotate(-79.83)">
      <tspan x="0" y="0">g</tspan>
    </text>
    <text class="cls-1" transform="translate(216.09 454.6) rotate(-66.67)">
      <tspan x="0" y="0"></tspan>
    </text>
    <text class="cls-1" transform="translate(231.06 416.72) rotate(-58.08)">
      <tspan x="0" y="0">•</tspan>
    </text>
  </svg>
</template>

<script setup></script>

<style lang="scss" scoped>
.cls-1 {
  font-size: 243px;
  font-weight: 500;
}
</style>
